// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "x_sg d_gS d_cw d_dv";
export var quoteParagraphCenter = "x_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "x_gV d_gV d_cw d_dx";
export var quoteRowLeft = "x_sh d_bG";
export var quoteRowCenter = "x_sj d_bD";
export var quoteRowRight = "x_sk d_bH";
export var quoteWrapper = "x_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "x_gR d_gR";
export var quoteExceptionSmall = "x_sl C_sl";
export var quoteExceptionNormal = "x_sm C_sm";
export var quoteExceptionLarge = "x_sn C_sn";
export var quoteAuthorExceptionSmall = "x_sp C_sp";
export var quoteAuthorExceptionNormal = "x_sq C_sq";
export var quoteAuthorExceptionLarge = "x_sr C_sr";