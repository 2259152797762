// extracted by mini-css-extract-plugin
export var alignLeft = "z_pB d_fp d_bG d_dv";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignRight = "z_pC d_fr d_bH d_dx";
export var testimonialsContainer = "z_sB d_dW";
export var testimonialsContainerFull = "z_sC d_dT";
export var testimonialsMainHeader = "z_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "z_jy d_jy";
export var testimonialsComponentSmall = "z_jx d_jx";
export var testimonialsComponentsWrapper = "z_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "z_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "z_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "z_sD d_d7";
export var colEqualHeight = "z_sF d_bz";
export var testimonialsColumnWrapper = "z_jz d_jz d_b3";
export var testimonialsImageWrapper = "z_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "z_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "z_jr d_jr d_Z d_bz";
export var design2TextWrapper = "z_sG d_bC d_bP d_bJ";
export var design3 = "z_sH d_cD d_H";
export var imageDesign2 = "z_sJ";
export var SmallSmall = "z_q9 C_q9 C_ts C_tQ";
export var SmallNormal = "z_rb C_rb C_ts C_tR";
export var SmallLarge = "z_rc C_rc C_ts C_tN";
export var exceptionWeight = "z_sK C_tV";