// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_rk d_bD";
export var datasheetRowWrapper = "s_lt d_lt d_bK";
export var datasheetLeftWrapper = "s_rl d_bz d_bP";
export var datasheetWrapperFull = "s_rm d_cD";
export var datasheetWrapperFullLeft = "s_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "s_mv d_lv";
export var contentWrapperCenter = "s_rn d_lz";
export var datasheetLeftWrapperCenter = "s_rp d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "s_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "s_rq d_lw";
export var datasheetFooter = "s_ly d_ly";
export var alignLeft = "s_pB d_dv";
export var alignCenter = "s_bP d_dw";
export var alignRight = "s_pC d_dx";
export var datasheetInnerWrapperNewLeft = "s_rr d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "s_rs d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "s_rt d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "s_rv d_bK";
export var datasheetSubtitle = "s_rw d_cx";
export var tableRow = "s_rx";
export var cell = "s_ry";
export var tableRowWrapper = "s_rz d_w d_dv";
export var tableHeadingCell = "s_rB";
export var tableHeading = "s_rC";
export var tableRowStriped = "s_rD";
export var tableRowHeading = "s_rF";
export var dataTable = "s_rG d_w";
export var imageWrapper = "s_q8 d_fg d_Z";
export var imageWrapperFull = "s_rH d_H d_w d_bf d_Z";
export var imageWrapperIcon = "s_rJ";
export var titleMargin = "s_rK d_cs";
export var datasheetInnerInnerWrapper = "s_rL d_w";
export var hasLabels = "s_rM";
export var label = "s_rN";
export var SmallSmall = "s_q9 C_q9 C_ts C_tQ";
export var SmallNormal = "s_rb C_rb C_ts C_tR";
export var SmallLarge = "s_rc C_rc C_ts C_tN";