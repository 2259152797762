// extracted by mini-css-extract-plugin
export var customText = "r_rd d_dv d_cs d_cg";
export var videoIframeStyle = "r_pW d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "r_rf d_cs d_cg d_Z";
export var customRow = "r_pD d_bD d_bf";
export var quoteWrapper = "r_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "r_p0 d_H";
export var masonryImageWrapper = "r_p6";
export var title = "r_q0";
export var Title3Small = "r_rg C_rg C_ts C_tB";
export var Title3Normal = "r_rh C_rh C_ts C_tC";
export var Title3Large = "r_rj C_rj C_ts C_tD";