// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rP d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rQ d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rR d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rS d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rT d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rV d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rW d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rX C_rX";
export var heroExceptionNormal = "t_rY C_rY";
export var heroExceptionLarge = "t_rZ C_rZ";
export var Title1Small = "t_r0 C_r0 C_ts C_tt";
export var Title1Normal = "t_r1 C_r1 C_ts C_tv";
export var Title1Large = "t_r2 C_r2 C_ts C_tw";
export var BodySmall = "t_r3 C_r3 C_ts C_tM";
export var BodyNormal = "t_r4 C_r4 C_ts C_tN";
export var BodyLarge = "t_r5 C_r5 C_ts C_tP";