// extracted by mini-css-extract-plugin
export var campaignContainer = "q_qT d_dW";
export var campaignContainerFull = "q_qV d_dT";
export var wrapper = "q_qW d_w";
export var leftWrapper = "q_qX d_bz d_bN";
export var rightWrapper = "q_qY";
export var subtitle = "q_qZ";
export var title = "q_q0";
export var text = "q_qf d_cs";
export var inlineWrapper = "q_q1 d_bC d_bG d_w";
export var btnsWrapper = "q_q2 q_q1 d_bC d_bG d_w d_bJ";
export var priceWrapper = "q_q3 q_q1 d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "q_q4";
export var disclaimerWrapper = "q_q5 d_cD";
export var mediaSlot = "q_q6";
export var listWrapper = "q_q7 d_bC d_bP d_w";
export var btnWrapper = "q_d2";
export var imageWrapper = "q_q8 d_fg d_Z";
export var SmallSmall = "q_q9 C_q9 C_ts C_tQ";
export var SmallNormal = "q_rb C_rb C_ts C_tR";
export var SmallLarge = "q_rc C_rc C_ts C_tN";
export var textLeft = "q_dv";
export var textCenter = "q_dw";
export var textRight = "q_dx";
export var alignLeft = "q_pB";
export var alignCenter = "q_bP";
export var alignRight = "q_pC";